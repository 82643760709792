<template>
  <div class="case">
    <Nav paths="/case" />
    <div class="banner">
      <div class="banner-bg"></div>
    </div>
    <div class="japen-case">
      <div class="en-title">Popularization Of Frozen Sleeping Food In Japan</div>
      <div class="chs-title">冻眠食品在日本的普及</div>
      <div class="p-title">
        <p>冻眠食品在日本已非常普及。</p>
        <p>大小餐厅、超市都能看到冻眠的身影。</p>
      </div>
      <div class="abroad-case">
        <div class="news-items">
          <el-col :span="5" v-for="(item, index) in new_list" :key="index" data-aos="fade-up" data-aos-duration="1000">
            <el-card :body-style="{ padding: '0px',border:'0' }" shadow="never">
              <img :src="item.image" class="image">
              <div class="news-content">
                <div class="news-content-title">{{item.title}}</div>
                <p class="news-content-value">{{item.value}}</p>
              </div>
            </el-card>
          </el-col>
        </div>
      </div>
      <div class="domestic-case">
        <div class="domestic-case-chs-title" data-aos="fade-up" data-aos-duration="1000">冻眠市场国内应用案例</div>
        <div class="domestic-case-en-title" data-aos="fade-up" data-aos-duration="1000">Domestic application cases of frozen sleep Market</div>
        <el-row :gutter="20" class="case-left">
          <el-col :span="10" data-aos="fade-right" data-aos-duration="1000">
            <div class="case-left-title">应用案例-冻眠斑节对虾(产品名称:庖小鲜)</div>
            <div class="case-left-img"><img :src="require('@/assets/case/case5.png')" width="340" height="510"></div>
          </el-col>
          <el-col :span="14" data-aos="fade-left" data-aos-duration="1000">
            <div class="case-left-desc" style="height:620px;">
              <p>将鲜活斑节虾煮熟后实施冻眠，一个月后取出，吃起来口感跟现煮的一样，完全吃不出是冷冻过的。 设立在珠澳跨境工业区专用口岸的珠澳通供应链有限公司，每 天从东南亚泰国、越南、马来西亚等国进口大量鲜活斑节虾，从澳门通关到跨境工业区熟食工厂，马上进行煮制，然后将煮好的斑节对虾分装打包，再立即采用冻眠技术锁鲜。大量销往香港各大酒楼、澳门赌场的自动餐厅以及内陆上海等各大城市。</p>
              <p>据珠澳通供应链有限公司陈总表示，通过冻眠后的熟虾完全保持了刚煮出来时的鲜度和脆度，口感更鲜美，消费者都非常喜欢。统一煮制和冻眠既提高了食品安全，又减少了食材浪费，降低了成本。该公司与泰克尼康合作，引入冻眠技术，大大提升了产品品质和市场竞争力，同时让更多人可以吃到正宗而又新鲜的斑节对虾。</p>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20" class="case-right">
          <el-col :span="14" data-aos="fade-right" data-aos-duration="1000">
            <div class="case-right-desc" style="height:512px;">
              <p>2020年的冬季是个吃火锅的季节，一盆用冻眠过的海南文昌鸡做的鸡肉火锅端上餐桌，香港歌手陈栋先生试吃后，赞不绝口，称鸡的肉质特别鲜嫩，完全吃不是出冷冻过的。</p>
              <p>文昌鸡是海南的地理特色产品，号称四大名菜之首，文昌鸡具有皮薄嫩滑、肉味馥香的特点，目前国内很多城市已经禁止活鸡售卖，海南文昌鸡养殖场陈总为了能让新鲜的文昌鸡销往全国，于2020年9月引进了冻眠技术，用于生产冻眠鸡，陈总表示，冻眠技术的引进解决了文昌鸡的饲养成本、物流成本高的问题，提升了鸡的品质，使海南本地的文昌鸡也可以直接面向全国市场。</p>
            </div>
          </el-col>
          <el-col :span="10" data-aos="fade-left" data-aos-duration="1000">
            <div class="case-right-title">应用案例-冻眠海南文昌鸡</div>
            <div class="case-right-img"><img :src="require('@/assets/case/case6.png')" width="500" height="374"></div>
          </el-col>
        </el-row>
        <el-row :gutter="20" class="case-left">
          <el-col :span="10" data-aos="fade-right" data-aos-duration="1000">
            <div class="case-left-title">应用案例-潮州牛肉丸</div>
            <div class="case-left-img noindent"><img :src="require('@/assets/case/case7.png')" width="501" height="347"></div>
          </el-col>
          <el-col :span="14" data-aos="fade-left" data-aos-duration="1000">
            <div class="case-left-desc" style="height:452px;">
              <p>珠港澳供应链有限公司陈总表示，冻眠牛肉在市场上刚刚起步，有试吃过的消费都对品质都非常认可，复购率非常高，冻眠技术将改变潮州牛肉丸长期以来物流供应急迫的局面。</p>
              <br>
              <p class="noindent">应用案例-美国牛肉</p>
              <p class="noindent">乔伊克林商贸(深圳)有限公司</p>
            </div>
          </el-col>
        </el-row>
      </div>
      <el-backtop :visibility-height="300" :bottom="100" />
    </div>
  </div>
</template>

<script>
import Nav from '@/components/nav'
export default {
  data () {
    return {
      new_list: [
        { image: require('@/assets/case/case1.png'), title: '伊势龙虾和金枪鱼冷冻加工', value: '樋長@东京都下江东区丰非洲市场，在日本最大的水产市场经营金枪鱼中介活动。使用冻眠冷冻高价金枪鱼，不受鱼期制约。' },
        { image: require('@/assets/case/case2.png'), title: 'TA西村@三重县鸟羽市', value: '日式旅馆高级日料御用海鲜水产供应商，使用东面销售伊势龙虾，金枪鱼等高级水产。' },
        { image: require('@/assets/case/case3.png'), title: '餐饮店使用冻眠', value: '意大利餐厅—zucca@东京都涉谷，精选日本食材，冻眠冷冻处理。冻眠已料理好的意式餐品，需要时解冻使用。' },
        { image: require('@/assets/case/case4.png'), title: '中华料理店-虎玄@爱知县丰田市', value: '川菜麻婆豆腐，担担面的专卖店使用冻眠冷冻。除店内使用外，还是用冻眠进行电商销售。' },
      ],
    }
  },
  mounted () {
    window.scrollTo(0, 0);
  },
  components: {
    Nav,
  }
}
</script>

<style lang="less" scoped>
.case {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 1600px;
  min-height: 2000px;
  .banner {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 750px;
    margin: 0 auto;
    .banner-bg {
      width: 1920px;
      height: 750px;
      background: url("../assets/case.png") no-repeat;
      background-size: cover;
      background-position: 50%;
    }
  }
  .japen-case {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 1400px;
    margin: 50px auto;
    & .en-title {
      font-size: 48px;
      font-weight: bold;
      color: #e6e6e6;
      letter-spacing: 5px;
      text-align: center;
    }
    & .chs-title {
      font-size: 36px;
      font-weight: bold;
      color: #000;
      letter-spacing: 5px;
      text-align: center;
      margin-top: -30px;
    }
    & .p-title {
      font-size: 22px;
      line-height: 36px;
      color: #000;
      letter-spacing: 3px;
      margin-top: 40px;
      text-align: center;
      margin-bottom: 80px;
    }
  }
  .abroad-case {
    width: 1400px;
    margin-bottom: 30px;
    .news-items {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      width: 1400px;
      /deep/ .image {
        width: 100%;
        height: 180px;
        border-radius: 5px;
      }
      .el-card {
        border: 0;
      }
      .news-content {
        .news-content-title {
          font-size: 26px;
          color: #35b3ed;
          margin: 20px 0 5px;
        }
        .news-content-value {
          font-size: 20px;
          color: #3b3d3d;
          line-height: 30px;
          margin: 0 0 20px;
        }
      }
    }
  }
  .domestic-case {
    width: 1400px;
    &-chs-title {
      font-size: 36px;
      font-weight: bold;
      color: #000;
      margin-top: 100px;
      margin-bottom: 10px;
      text-align: center;
    }
    &-en-title {
      font-size: 20px;
      color: #6c6d6e;
      text-transform: lowercase;
      text-align: center;
    }
    .case-left {
      padding-top: 100px;
      .case-left-title {
        font-size: 26px;
        font-weight: bold;
        text-indent: 25px;
        border-left: 4px solid #00a0e9;
        margin: 10px 0 60px;
      }
      .case-left-img {
        margin-left: 94px;
      }
      .case-left-desc {
        display: flex;
        flex-direction: column;
        width: 757px;
        padding: 60px;
        margin: 0 auto;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.08);
        & p {
          color: #3b3d3d;
          font-size: 22px;
          line-height: 36px;
          text-indent: 45px;
          letter-spacing: 3px;
        }
      }
      .noindent {
        text-indent: 0 !important;
        margin-left: 0 !important;
      }
    }
    .case-right {
      padding-top: 100px;
      .case-right-title {
        font-size: 26px;
        font-weight: bold;
        border-right: 4px solid #00a0e9;
        margin: 10px 0 60px;
        text-align: right;
        padding-right: 25px;
      }
      .case-right-img {
        text-align: right;
      }
      .case-right-desc {
        display: flex;
        flex-direction: column;
        width: 757px;
        padding: 60px;
        margin: 0 auto;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.08);
        & p {
          color: #3b3d3d;
          font-size: 22px;
          line-height: 36px;
          text-indent: 45px;
          letter-spacing: 3px;
        }
      }
    }
  }
}
</style>